<template>
  <v-card class="ml-5 pl-10 mt-5" elevation="0">
    <v-snackbar
      v-model="snackbar"
      bottom
      color="deep-purple accent-4"
    >
      Datos Actualizados {{this.title}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          class="ma-n1"
          text
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon small>mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-title class="ml-8 mb-5 ma-0 pa-0">
      <div class="d-flex">
        <v-avatar color="#8739EB" size="48">
          <span class="white--text text-h5">R</span>
        </v-avatar>
        <div class="d-flex flex-column align-start ml-5">
          <p class="text-body-1 ma-0 font-weight-bold">{{this.title}}</p>
          <div class="d-flex align-center">
            <p class="text-caption ma-0 pa-0">150 usuarios</p>
            <v-divider class="mx-2" vertical></v-divider>
            <div class="d-flex align-center">
              <v-icon small color="#A3A1FB" class="mr-1"
                >mdi-account-outline</v-icon
              >
              <p class="text-caption ma-0 font-weight-bold">
                Tipo de suscripción
              </p>
            </div>
            <div class="d-flex ml-16">
              <div class="d-flex align-center mr-10">
                <p
                  class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
                  style="background: #f2f4f7; color: #a4afc0"
                >
                  00
                </p>
                <p class="text-caption ma-0 font-weight-bold">Espacio total</p>
              </div>
              <div class="d-flex align-center">
                <p
                  class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
                  style="background: #e5e7fb; color: #a3a1fb"
                >
                  00
                </p>
                <p class="text-caption ma-0 font-weight-bold">
                  Espacio disponible
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card class="ml-8 mt-5 mb-16" elevation="0">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#8739EB"
        hide-slider
      >
        <v-tab class="text-none font-weight-bold caption">
          Detalles de la cuenta
        </v-tab>
        <v-tab class="text-none font-weight-bold caption" disabled>
          Configuración de pagos
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card elevation="0">
            <v-card-title class="d-flex align-center mt-5">
              <p class="text-body-1 ma-0 font-weight-bold mr-8">
                Datos generales
              </p>
              <v-btn
                small
                class="text-none caption"
                rounded
                outlined
                color="#8739EB"
                @click="(edit=!edit)"
                elevation="0"
                v-if="!edit"
              >
                <v-icon class="mr-1" small color="#8739EB">mdi-pencil-outline</v-icon>
                Editar perfil
              </v-btn>
              <v-btn
                small
                class="text-none caption"
                rounded
                outlined
                color="#8739EB"
                elevation="0"
                v-if="edit"
                @click="(edit=!edit), updateCompany(), (snackbar=true)">
                <v-icon class="mr-1" small color="#8739EB">mdi-content-save</v-icon>
                  Guardar cambios
              </v-btn>
            </v-card-title>
            <v-card elevation="0" class="mb-16">
              <v-divider></v-divider>
              <v-container class="ma-0 pa-0" fluid grid-list-md>
                <v-layout row wrap d-flex justify-start>
                  <v-flex d-flex justify-start xs12 sm12 md6>
                    <v-card
                      class="d-flex flex-column"
                      width="95%"
                      elevation="0"
                    >
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small
                            >mdi-account-outline</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Nombre
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit 
                        rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="companyName"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small
                            >mdi-phone-outline</v-icon
                          >
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Teléfono
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="phone"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-facebook</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Página de Facebook
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="facebookPage"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-web</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Página web
                          </p>
                        </div>
                        <a>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="webPage"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </a>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-whatsapp</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Whatsapp
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="whatsapp"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                    </v-card>
                  </v-flex>

                  <v-flex d-flex justify-start xs12 sm12 md6>
                    <v-card
                      class="d-flex flex-column"
                      width="95%"
                      elevation="0"
                    >
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small
                            >mdi-map-marker-outline</v-icon
                          >
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Calle
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="street"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Número
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="number"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Número interior
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="interiorNumber"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small
                            >mdi-city-variant-outline</v-icon
                          >
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Colonia
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="neighborhood"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-earth</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            País
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="country"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small
                            >mdi-city-variant-outline</v-icon
                          >
                          <p class="caption ma-0 font-weight-bold ml-3">
                            Ciudad
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="city"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                      <div class="d-flex align-center mt-5 mb-5">
                        <div class="d-flex align-center" style="width: 45%">
                          <v-icon color="#8739EB" small>mdi-numeric</v-icon>
                          <p class="caption ma-0 font-weight-bold ml-3">
                            CP
                          </p>
                        </div>
                        <v-text-field 
                        :filled=edit rounded dense hide-details 
                        class="mt-n4 mb-n4 d-flex caption ma-0 font-weight-light" v-model="zipCode"
                        :readonly=!edit
                        >
                        </v-text-field>
                      </div>
                      <v-divider></v-divider>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div v-if="companiesStateLoading ===  true">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div
            class="modal"
            role="dialog"
            area-labelledby="modalTitle"
            aria-describedby="modalDesc"
          >
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">
                espere un momento...
              </h4>
              <v-progress-linear
                color="#6E80F8"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <!-- <v-progress-circular
            color="white"
            size="40"
            width="6"
            indeterminate
          ></v-progress-circular> -->
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ENUM from "@/store/enums";

export default {
  name: "Settings",
  data: () => ({
    snackbar: false,
    title: '',
    companyName:'',
    tab: null,
    edit: false,
    phone: '',
    facebookPage: '',
    webPage: '',
    whatsapp: '',
    street: '',
    number: '',
    interiorNumber: '',
    neighborhood: '',
    country: '',
    city: '',
    zipCode: '',

  }),
  methods: {
    ...mapActions(["updateCompanyById"]),
    updateCompany(){
      this.title = this.companyName;
      const payload = {
        //company_id: this.getCompany.company_id,
        company_name: this.companyName,
        superadmin: this.getCompany.superadmin,
        company_phone: this.phone,
        company_social_media: {
          company_whatsapp: this.whatsapp,
          company_web_page: this.webPage,
          company_facebook_page: this.facebookPage,
        },
        company_address: {
          company_interior_number: this.interiorNumber,
          company_country: this.country,
          company_neighborhood: this.neighborhood,
          company_zip_code: this.zipCode,
          company_street: this.street,
          company_street_number: this.number,
          company_city: this.city
        },
        company_holidays: this.getCompany.company_holidays,
        company_maximum_users: this.getCompany.company_maximum_users,
        company_maximum_vacancies: this.getCompany.company_maximum_vacancies,
        company_maximum_zones: this.getCompany.company_maximum_zones,
        company_id: this.getCompany.company_id
      }
      this.updateCompanyById(payload);

    },
  },
  computed: {
    ...mapGetters(["getSelectedCompany", "getCompany"]),
    ...mapState({
      companiesState: (state) => state.company.status,
    }),
    companiesStateLoading() {
      return this.companiesState === ENUM.LOADING;
    }
  },
  watch:{
    getCompany(){
      this.phone = this.getCompany.company_phone
      this.facebookPage = this.getCompany.company_social_media.company_facebook_page
      this.webPage = this.getCompany.company_social_media.company_web_page
      this.whatsapp = this.getCompany.company_social_media.company_whatsapp
      this.street = this.getCompany.company_address.company_street
      this.number = this.getCompany.company_address.company_street_number
      this.interiorNumber = this.getCompany.company_address.company_interior_number
      this.neighborhood = this.getCompany.company_address.company_neighborhood
      this.country = this.getCompany.company_address.company_country
      this.zipCode = this.getCompany.company_address.company_zip_code
      this.city = this.getCompany.company_address.company_city
      this.companyName = this.getCompany.company_name
      this.title = this.getCompany.company_name
    }
  },
  beforeCreate() {
    this.$store.dispatch("requestsAllCompanies");
  },

  

};
</script>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    width: 25vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center !important;
    background: transparent;
    box-shadow: 0;
    overflow: hidden;
  }
  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }
  .modal-fade-enter-active,
  modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }
  h4 {
    color: #fff;
    letter-spacing: 2px;
  }
</style>
